import { atom } from 'recoil';

export const globalRouteTransitionState = atom<boolean>({
  key: 'globalRouteTransitionState',
  default: false
});

import type {
  Challenge,
  UserAddressType
} from '@source/graphql/autogenerate/schemas';
import type {
  Addresses,
  CardCheckoutDataType,
  CartAndStepController,
  Coupon,
  CriatedPixPaymentData,
  ElegibleSplitUsersType,
  OptionalProductsPurchase,
  Participant,
  PayerDataType,
  PreCheckoutCartType,
  Product,
  SelectedAddresses,
  SplitPaymentType,
  UserCheckoutDataType
} from './types';
import { ParticipantsTeamType } from '@source/components/Customize/Categories/components/CategoriesContent';

export const cartState = atom<Product[]>({
  key: 'cartState',
  default: []
});

export const appliedDiscountCoupon = atom<Coupon>({
  key: 'appliedDiscountCoupon',
  default: {} as Coupon
});

// Manages the checkout challenge details
export const challengeSlugDetailsState = atom<Challenge>({
  key: 'challengeSlugDetailsState',
  default: undefined
});

export const userAddressesState = atom<Addresses>({
  key: 'userAddressesState',
  default: {
    shipping: {} as UserAddressType,
    billing: {} as UserAddressType,
    withdrawal_id: null
  }
});

export const selectedAddressesState = atom<SelectedAddresses>({
  key: 'selectedAddressesState',
  default: {
    selectedShippingAddressID: null,
    withdrawalAddressID: null
  }
});

export const canCheckoutProceedState = atom<boolean>({
  key: 'canCheckoutProceedState',
  default: false
});

export const cartControllerState = atom({
  key: 'cartControlState',
  default: {
    type: '',
    originUrl: '',
    checkParticipants: false,
    checkCustomize: false,
    checkPayment: false,
    no_kit: false,
    number_of_components: 0
  }
});

export const userCheckoutDataState = atom<UserCheckoutDataType>({
  key: 'userCheckoutDataState',
  default: {
    data: {
      firstname: '',
      lastname: '',
      legal_registry_number: '',
      gender: '',
      date_of_birth: '',
      phone_number: '',
      ddi: '',
      team_name: '',
      username: ''
    },
    isDirty: false
  }
});

export const cardCheckoutDataState = atom<CardCheckoutDataType>({
  key: 'cardCheckoutDataState',
  default: {
    name: '',
    card_number: '',
    card_expiration_date: '',
    card_cvv: '',
    card_holder_name: '',
    legal_holder_number: ''
  }
});

export const triggerSubmit = atom({
  key: 'triggerSubmit',
  default: false
});

export const paymentDataState = atom({
  key: 'paymentDataState',
  default: {
    method: 'credit',
    credit_card: '',
    brand: '',
    first_digits: '',
    last_digits: '',
    value_paid: null,
    value_without_fee: null,
    installments: 1,
    installments_value: null
  }
});

export const challengeDetailsState = atom<Challenge | undefined>({
  key: 'challengeDetailsState',
  default: undefined
});

export const kitsPurchaseModalState = atom<boolean>({
  key: 'kitsPurchaseModalState',
  default: false
});

export const productsPurchaseModalState = atom<{
  isOpen: boolean;
  params: string[] | null;
}>({
  key: 'productsPurchaseModalState',
  default: {
    isOpen: false,
    params: null
  }
});

export const missingFieldErrorState = atom({
  key: 'missingFieldErrorState',
  default: false
});

// // Controla o kit selecionado no ato de inscrição
// export const currentAwardState = atom<ChallengeAwards>({
//   key: 'currentAward',
//   default: {} as ChallengeAwards
// });

export const participantsState = atom<Participant[]>({
  key: 'participantsState',
  default: []
});

// group subscription status - always true, except when a category has a group subscription attached to it. In such case, the user must select all participants and choose a team name in order to make this true
export const groupSubscriptionsState = atom<boolean>({
  key: 'groupSubscriptionsState',
  default: true
});

// participants teams management
export const participantsTeamsState = atom<ParticipantsTeamType[]>({
  key: 'participantsTeamsState',
  default: []
});

// optional products purchases
export const optionalProductsState = atom<OptionalProductsPurchase[]>({
  key: 'optionalProductsState',
  default: []
});

// pre-checkout app cart
export const preCheckoutCartState = atom<PreCheckoutCartType>({
  key: 'preCheckoutCartState',
  default: {
    challenge_id: '',
    challenge_name: '',
    challenge_url: '',
    user_subscription: '',
    optional_products_price: 0,
    optional_products: []
  }
});

// pre-checkout drawer visibility
export const isCartDrawerOpenState = atom<boolean>({
  key: 'isCartDrawerOpenState',
  default: false
});

// split payment
export const splitPaymentState = atom<SplitPaymentType[]>({
  key: 'splitPaymentState',
  default: []
});

// subscription payer data
export const payerDataState = atom<PayerDataType | null>({
  key: 'payerDataState',
  default: null
});

// split payment elegible users
export const usersElegibleForSplitPaymentState = atom<ElegibleSplitUsersType[]>(
  {
    key: 'usersElegibleForSplitPaymentState',
    default: []
  }
);

export const saveParticipantsState = atom<boolean>({
  key: 'saveParticipantsState',
  default: false
});

export const cartAndStepsControllerState = atom<CartAndStepController[]>({
  key: 'cartAndStepsControllerState',
  default: []
});

export const shippingAddressToRenderState = atom<UserAddressType | undefined>({
  key: 'shippingAddressToRenderState',
  default: undefined
});

export const discountValueState = atom<number>({
  key: 'discountValueState',
  default: 0
});

export const notOwnSubscriptionState = atom({
  key: 'notOwnSubscriptionState',
  default: false
});

export const branchLinkState = atom({
  key: 'branchLinkState',
  default: process.env.NEXT_PUBLIC_DEFAULT_SHARE_LINK as string
});
export const ERPFildesComplete = atom({
  key: 'ERPFildeStat',
  default: false
});

export const CompanyHasCpfcnpjIntegration = atom({
  key: 'companyIntegrationCpfCnpj',
  default: false
});
export const CompanyIdChallengeState = atom({
  key: 'companyIdChallenge',
  default: ''
});


export const formStepAtom = atom<number>({
  key: 'formStepAtom',
  default: 1
});

export const PixPaymentCreated = atom({
  key: 'createdPixPaymentCreated',
  default: false
});
export const CouponIsApprovedState = atom({
  key: 'couponIsApproved',
  default: false
});

export const PixPaymentCreatedData = atom<CriatedPixPaymentData>({
  key: 'createdPixPaymentCreatedData',
  default: {
    qrCode: '',
    copy_and_past: ''
  }
});

export const isStaffState = atom({
  key: 'isStaffState',
  default: false
});