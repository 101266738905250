import { isValid, parse, parseISO } from 'date-fns';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

export function formatDate(date: string | Date) {
  try {
    if (!date) {
      return '';
    }

    return format(Date.parse(date.toString()), "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

// Output: "dia mes ano"
export const formatDateWithShortMonth = inputDate => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('pt-BR', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2);

  return `${day} ${month} ${year}`;
};

export const formatDateWithShortMonthComplete = inputDate => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('pt-BR', { month: 'short' });
  const year = date.getFullYear().toString();

  return `${day} de ${month} ${year}`;
};

export function formatDateShort(date: string | Date) {
  try {
    if (!date) {
      return '';
    }

    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return format(dateObj, 'dd/MM/yyyy', { locale: ptBR });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatDateShortInputFormat(
  date: string | Date | undefined | null
): string {
  try {
    if (!date) {
      return '';
    }

    let dateObj: Date;

    if (date instanceof Date) {
      dateObj = date;
    } else if (typeof date === 'string') {
      // Try parsing the extended date string
      dateObj = parse(date, 'EEEE, MMMM d, yyyy', new Date(), { locale: ptBR });
      
      // If parsing fails, try another format or directly convert to Date if possible
      if (!isValid(dateObj)) {
        dateObj = new Date(date); // This works for ISO strings or other JS-readable formats
      }
    } else {
      throw new Error('Invalid date type');
    }

    // Check if the parsed date is valid
    if (!isValid(dateObj)) {
      throw new Error('Invalid date');
    }

    return format(dateObj, 'yyyy-MM-dd', { locale: ptBR });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatDateShortNoTimezone(date: string | Date | undefined) {
  try {
    if (!date) {
      return '';
    }

    type DateType = {
      timeZone: 'UTC';
      month: 'numeric';
      day: 'numeric';
      year: 'numeric';
    };

    const dateOptions: DateType = {
      timeZone: 'UTC',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    };

    const dateFormatter = new Intl.DateTimeFormat('pt-BR', dateOptions);
    return dateFormatter.format(new Date(date));
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export const formatDateShortInputFormatNoTimezone = (
  inputDate: Date | string
) => {
  return formatDateShortNoTimezone(inputDate).split('/').reverse().join('-');
};

export function formatDateTime(date: string | Date) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), "dd 'de' MMMM 'de' yyyy 'às' HH':'mm", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatDateShortTime(date: string | Date) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), "dd/MM/yyyy ' ' HH':'mm", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatTime(date: string | Date) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), "HH':'mm", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function relativeTime(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  const formatter = new Intl.RelativeTimeFormat('pt-BR');
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (const key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(
        Math.round(delta),
        key as Intl.RelativeTimeFormatUnit
      );
    }
  }
}
