import { api } from '@source/services/api';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/legacy/image';
import { useState } from 'react';
import AppDownloadDrawer from './AppDownloadDrawer';
import { parseCookies, setCookie } from 'nookies';
import { useRouter } from 'next/router';
import * as S from './styles';

const pagesToExclude = [
  'api',
  'checkout',
  'dashboard',
  'share',
  'ranks',
  'legal',
  'login',
  'logout',
  'confirm-subscription',
  'server-sitemap',
  'forgotPassword',
  'redirect',
  'register',
  'garmin',
  'polar',
  'strava',
  'wahoo',
  'workshop',
  'oauth',
  'print',
  'quote'
];

type DownloadAppDrawerProviderProps = { children: React.ReactNode };

const DownloadAppDrawerProvider: React.FC<DownloadAppDrawerProviderProps> = ({
  children
}): JSX.Element => {
  const router = useRouter();

  const isPageAvailable = !pagesToExclude.some(page =>
    router.pathname.includes(page)
  );

  const cookies = parseCookies();
  const isDownloadAppDrawerVisibleCookie =
    cookies['riderize@is-download-app-drawer-visible'];
  const isRedirectTopBar = cookies['riderize@download-app-hidden'];
  const isDownloadAppDrawerVisible =
    typeof isDownloadAppDrawerVisibleCookie === 'string'
      ? !!isDownloadAppDrawerVisibleCookie
      : true;

  const [isDrawerVisible, setIsDrawerVisible] = useState(
    isDownloadAppDrawerVisible
  );
  const [removeDrawer, setRemoveDrawer] = useState(isRedirectTopBar);

  const { data: { data: { deviceType = '' } = {} } = {} } = useQuery({
    queryKey: ['getDeviceType'],
    queryFn: async () => {
      return await api.get('/get-device-type');
    }
  });

  const handleDrawerVisible = (visible: boolean) => {
    setIsDrawerVisible(visible);

    setCookie(
      undefined,
      'riderize@is-download-app-drawer-visible',
      visible ? 'true' : '',
      {
        maxAge: 60 * 60,
        path: '/'
      }
    );
  };

  return (
    <>
      {children}
        <>
          {deviceType && deviceType !== 'desktop' && isPageAvailable ? (
            <>
              {!isDrawerVisible ? (
                <S.ButtonOverlay onClick={() => handleDrawerVisible(true)}>
                  <Image
                    src="/icons/google-play-icon.svg"
                    width={20}
                    height={20}
                    alt="Google Play"
                    style={{ marginTop: '0.2rem' }}
                  />
                  <Image
                    src="/icons/apple-icon.svg"
                    width={20}
                    height={20}
                    alt="Apple"
                  />
                </S.ButtonOverlay>
              ) : null}

              {isDrawerVisible ? (
                <AppDownloadDrawer
                  handleDrawerVisible={handleDrawerVisible}
                  router={router}
                />
              ) : null}
            </>
          ) : null}
        </>
    </>
  );
};

export default DownloadAppDrawerProvider;